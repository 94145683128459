import React from 'react';
import Layout from '../components/Layout';

export default function NotFound() {

    return (
        <Layout allowAnonymous={true}>
            Unfortunately this page doesn't exist.
        </Layout>
    )
}